import React, { Component } from 'react'
import Landing from '../../../../components/case-studies/Landing'
import AboutProject from '../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../components/case-studies/PagesShowcase'
import ProjectShortDescription from '../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../components/case-studies/FontShowcase'
import Footer from '../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../components/reveal'
import ProjectImageLightBox from '../../../../components/case-studies/ProjectImageLightBox'
import Testimonials from '../../../../components/testimonials/Testimonials'
//import MobileCarousel from '../../../../components/case-studies/MobileCarousel'

class Flipwork extends Component {
    render() {
        const { data } = this.props
        const { banner_url_app, screens: screens_app } = data.project_app.childMarkdownRemark.frontmatter
        const { banner_url, screens } = data.project.childMarkdownRemark.frontmatter

        return (
            <Layout showBackButton={true}>
                <Landing
                    title={'Tvorba mobilnej aplikácie Flipwork'}
                    description={'Prípadová štúdia'}
                    description2={'Moderná appka a portál vrátane UX dizajnu, tvorby loga a kompletného brandingu.'}
                    text={'Nová moderná platforma, ktorá spája uchádzačov o zamestnanie, ktorí si môžu intuitívne a prehľadne nájsť svoj vysnívaný job, a kde sa zároveň firmy môžu prezentovať na profesionálnej úrovni.'}
                    className={'non-full-height'}
                    img={data.landingImage}
                    what_we_done={[
                        { text: 'tvorba názvu' },
                        { text: 'logo & branding', link: '/nase-prace/grafika/logo-branding/flipwork-branding' },
                        { text: 'ux & app design' },
                        { text: 'UI portál' },
                        { text: 'UI mobilná aplikácia' }
                    ]}
                    noShadow={true}
                />

                <AboutProject
                    title={'Vývoj mobilnej aplikácie na mieru od A po Z'}
                    text="Intuitívna a funkčná appka si vyžaduje profesionálny prístup."
                    list={[
                        {
                            title: 'Uchopenie projektu',
                            text: 'Klient nás oslovil s projektom, kde zadaním bol dizajn profesionálnej <a class="blue-link" href="/tvorba-mobilnych-aplikacii">mobilnej aplikácie</a> a portálu informujúci o dostupných pracovných ponukách na trhu, vrátane kompletného brandingu. Najskôr sme si s klientom prešli všetky požiadavky, aby sme <b>pochopili jeho očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
                        },
                        {
                            title: 'Návrh riešenia',
                            text: 'Cieľom bola mobilná aplikácia na mieru, v ktorej by sa vedeli uchádzači rýchlo zorientovať a firmy sa mohli prezentovať na úrovni. Vďaka <a class="blue-link" href="/ux-ui-dizajn">intuitívnemu dizajnu</a> musí byť už na prvý klik jasné ako funguje, a zároveň premyslieť do detailu všetky interakcie UI. V rámci úzkej spolupráce sme s klientom diskutovali, ako tieto plány zrealizovať.',
                        },
                        {
                            title: 'Priebeh vývoja',
                            text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Ďalej sa vymyslel originálny názov, varianty loga a celá <a class="blue-link" href="/branding">korporátna identita</a>. Po schválení sme nakreslili wireframy (rozloženia stránok) a finálny dizajn aplikácie. Pravidelne sme klientovi prezentovali výstupy <b>a diskutovali o nich</b>. ',
                        },
                        {
                            title: 'Úspešný výsledok',
                            text: 'Pred samotným spustením appky optimalizujeme, vylaďujeme detailne responzívnosť a všetko finálne testujeme. Odovzdaním spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Samozrejmosťou pre programovanie Android, iOS či multiplatformových appiek je <b>spoľahlivý servis a optimalizácia</b>, aby appky išli s dobou. ',
                        }
                    ]}
                />

                <ProjectShortDescription
                    title={'Tvorba brandu'}
                    description={'Predpokladom plnohodnotnej firemnej identity je pochopenie projektu a jeho zámeru. Návrh loga prichádza potom.'}
                    text={'Po konzultáciách s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal projekt prezentovať. Následne sme odkomunikovali základné piliere toho, ako sa <b>odlíšiť od konkurencie</b> a cez nový branding značky ukázať jedinečnú esenciu firmy.'} />

                <Reveal>
                    <div className={'container p-0 mb-4'}>
                        <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
                    </div>
                </Reveal>

                <ColorShowcase colors={[
                    { code: '#2e3192', tintColorCode: '#585aa8', nameColor: '#FFFFFF', name: 'Ultramarínová' },
                    { code: '#d82470', tintColorCode: '#e0508d', nameColor: '#FFFFFF', name: 'Magenta' },
                    { code: '#a11153', tintColorCode: '#b44175', nameColor: '#FFFFFF', name: 'Malinová' },
                    { code: '#c7c8ca', tintColorCode: '#d2d3d5', nameColor: '#6B7B8A', name: 'Holubia šedá' },
                ]} />

                <FontShowcase
                    fontName={'Proxima Nova'}
                    text={'Rodina písiem Proxima nova je kompletnou prerábkou písma Proxima Sans (1994). Pôvodných šesť písiem (tri rezy s kurzívami) bolo rozšírených na 48 plnohodnotných písiem typu OpenType.<br><br>Štylisticky písmo Proxima Nova vypĺňa medzeru medzi písmami ako je Futura a klasickými bezpätkovými písmami. Výsledkom je hybrid, ktorý kombinuje humanistické proporcie s miernym geometrickým nádychom.'}
                    isFullWidth={false}
                    fontExampleSvg={'/images/svg/fonts/proxima-nova.svg'}
                />

                <Reveal>
                    <div className={'short-description-center'}>
                        <h2>Na detailoch záleží</h2>
                        <p className="description">Precíznosť v každej maličkosti</p>
                        <p className="description-smaller">Dokonalosť spočíva v detailoch. Na prvý pohľad jednoduchá tvorba loga ukrýva oveľa viac, ako sa zdá. Držať sa minimalizmu a pritom docieliť estetickú príťažlivosť – to je výzva pre každé grafické oddelenie.</p>

                    </div>
                </Reveal>

                <Reveal>
                    <div className={'container p-0'}>
                        <Img className={'mb-5 mt-big'} alt={this.constructor.name} fluid={data.corporateIdentity.childImageSharp.fluid} />
                    </div>
                </Reveal>

                <PagesShowcase
                    fixedTitleWidth={true}
                    title={'Dizajn aplikácie na mieru'}
                    description={'Tvorba mobilnej aplikácie sa bez neho nezaobíde.'}
                    text={'Dizajnové prevedenie projektu Flipwork spĺňa najvyššie UX štandardy. Okrem samotného obsahu je dôležité, ako na návštevníka pôsobí celkový dizajn. Jeho úspešnosť sa odvíja od prehľadnosti a intuitívnosti.To je predpokladom k udržaniu záujmu návštevníka a zníženej miery odchodov. '}
                    projectDetail={'/nase-prace/aplikacie/flipwork-app'}
                    pages={[
                        {
                            name: 'Hlavná stránka',
                            image: data.pageOne,
                        },
                        {
                            name: 'Pracovné ponuky',
                            image: data.pageThree,
                        },
                        {
                            name: 'List',
                            image: data.pageFour,
                        },
                        {
                            name: 'Detail správy',
                            image: data.pageSeven,
                        },
                        {
                            name: 'Detail profilu',
                            image: data.pageEight,
                        },
                        {
                            name: 'Detail ponuky',
                            image: data.pageNine,
                        },
                        {
                            name: 'O nás',
                            image: data.pageEleven,
                        },
                        {
                            name: 'Aktuality',
                            image: data.pageThirteen,
                        }
                    ]} />

                <Reveal>
                    <div className='container mt-5 mb-5 pt-5 pb-5'>
                        <ProjectImageLightBox bannerUrl={banner_url_app} screens={screens_app} sectionTitle="Podstránky aplikácie" title="flipwork" />
                    </div>
                </Reveal>

                <Reveal>
                    <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox casestudy-lightbox'>
                        <ProjectImageLightBox bannerUrl={banner_url} screens={screens} sectionTitle="Podstránky portálu" title="flipwork" />
                    </div>
                </Reveal>

                <div className={'dark-section'}>
                    <Reveal>
                        <div className={'container wrapper'}>
                            <div className={'row'}>
                                <div className={'col-md-7'}>
                                    <h2 className={'cs-title'}>Dizajn mobilnej aplikácie</h2>
                                    <p className="description">Dokonalé UI a UX v každom jednom detaile.</p>
                                    <p className="description-smaller">Vytvoriť responzívnu appku do posledného detailu je kľúčové, pretože určuje, či bude <a href='/ux-ui-dizajn' class='red-link'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na všetkých typoch smartfónov, tabletov a ďalších rozlíšeniach. <br /><br />Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastnú appku a chce, aby mu prinášala čo najväčší zisk.</p>
                                </div>
                                <div className={'col-md-5'}>
                                    <Img alt={this.constructor.name} fluid={data.icon.childImageSharp.fluid} />
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-md-4 img-col-1'}>
                                    <Img alt={this.constructor.name} fluid={data.screenOne.childImageSharp.fluid} />
                                    <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenTwo.childImageSharp.fluid} />
                                    <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenThree.childImageSharp.fluid} />
                                    <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenFour.childImageSharp.fluid} />
                                    <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenFive.childImageSharp.fluid} />

                                </div>
                                <div className={'col-md-4 img-col-2'}>
                                    <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenSix.childImageSharp.fluid} />
                                    <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenSeven.childImageSharp.fluid} />
                                    <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenEight.childImageSharp.fluid} />
                                </div>
                                <div className={'col-md-4 img-col-3'}>
                                    <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenNine.childImageSharp.fluid} />
                                    <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenTen.childImageSharp.fluid} />
                                    <Img className={'mt-4'} alt={this.constructor.name} fluid={data.screenEleven.childImageSharp.fluid} />
                                </div>
                            </div>
                        </div>
                    </Reveal>
                </div>

                {/* Testimonial */}
                <Reveal otherClassName="mt-5">
                    <Testimonials
                        references={data.saidAboutUs.childMarkdownRemark.frontmatter.references.filter(r => r.author.search('Flipwork') > -1)}
                        addContainer={true}
                        enableSaidAboutUs={false}
                        sliderClassName='my-0'
                        className="pb-0"
                    />
                </Reveal>

                {/*<MobileCarousel
          icon={data.icon}
          items={[
            data.screenTwo,
            data.screenThree,
            data.screenFour,
            data.screenFive,
            data.screenSix,
            data.screenSeven,
            data.screenEight,
            data.screenNine,
            data.screenTen,
            data.screenEleven,
          ]}
        />*/}

                {/*        <ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

                <div className="extern-page-link">
                    <a rel="noreferrer" href="https://www.flipwork.sk" target='_blank' className='link-secondary'>www.flipwork.sk</a>
                </div>

                <Footer
                    title={'OurServices.Process'}
                    subtitle={'OurServices.ProcessSubTitle'}
                    link={'/o-nas/ako-pracujeme'}
                    leftTopTitle='project'
                    nextProjectName={'Supro'}
                    nextProjectUrl={'/nase-prace/pripadove-studie/aplikacie/supro/'}
                />
            </Layout>
        )
    }
}

export const query = graphql`{
  saidAboutUs: file(relativePath: {eq: "pages/sk/homepage/said_about_us.md"}) {
      childMarkdownRemark {
          frontmatter {
              text_paragraph
              references {
                description
                 logo: logo {
                    childImageSharp {
                        fluid(maxWidth: 200) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                logoBig: logo {
                    childImageSharp {
                        fluid(maxWidth: 200) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                  author
              }
          }
      }
  }
  project:  file(relativePath: {eq: "posts/case-studies/flipwork.md"}) {
        childMarkdownRemark {
          frontmatter {
            banner_url
              screens {
                  href {
                      childImageSharp {
                          fluid(quality: 100, maxWidth: 1920) {
                              ...GatsbyImageSharpFluid_withWebp
                              presentationWidth
                          }
                      }
                  }
                  alt
              }
          }
           
        }
    }
    project_app:  file(relativePath: {eq: "posts/case-studies/flipwork_app.md"}) {
        childMarkdownRemark {
          frontmatter {
            banner_url
              screens {
                  href {
                      childImageSharp {
                          fluid(quality: 100, maxWidth: 1920) {
                              ...GatsbyImageSharpFluid_withWebp
                              presentationWidth
                          }
                      }
                  }
                  alt
              }
          }
           
        }
    }
    landingImage: file(relativePath: { eq: "case-studies/Flipwork_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/fliphp.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/filter_rozklik.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/pracovne_ponuky_dlazdice_detail.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFour: file(relativePath: { eq: "case-studies/pracovne_ponuky_zoznam_detail.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFive: file(relativePath: { eq: "case-studies/firma_spojenia.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSix: file(relativePath: { eq: "case-studies/firma_novasprava.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSeven: file(relativePath: { eq: "case-studies/firma_detailspravy.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageEight: file(relativePath: { eq: "case-studies/detail_profil_novafirma.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageNine: file(relativePath: { eq: "case-studies/detail_pracovnej_ponuky.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTen: file(relativePath: { eq: "case-studies/edit_ponuky.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageEleven: file(relativePath: { eq: "case-studies/onas.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwelve: file(relativePath: { eq: "case-studies/flipfaq.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThirteen: file(relativePath: { eq: "case-studies/aktuality.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFourteen: file(relativePath: { eq: "case-studies/flipkontakt.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFifteen: file(relativePath: { eq: "case-studies/prihlasenie.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSixteen: file(relativePath: { eq: "case-studies/registracia_zamestnanec_v2_step_4.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/Flipwork_logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    corporateIdentity: file(relativePath: { eq: "case-studies/Flipwork_ci.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    icon: file(relativePath: { eq: "case-studies/Flipwork_icon.png" }) {
        childImageSharp {
            fluid( maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenOne: file(relativePath: { eq: "case-studies/Flipwork_logoOne.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenTwo: file(relativePath: { eq: "case-studies/Flipwork_logoTwo.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenThree: file(relativePath: { eq: "case-studies/Flipwork_logoThree.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenFour: file(relativePath: { eq: "case-studies/Flipwork_logoFour.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenFive: file(relativePath: { eq: "case-studies/Flipwork_logoFive.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenSix: file(relativePath: { eq: "case-studies/Flipwork_logoSix.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenSeven: file(relativePath: { eq: "case-studies/Flipwork_logoSeven.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenEight: file(relativePath: { eq: "case-studies/Flipwork_logoEight.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenNine: file(relativePath: { eq: "case-studies/Flipwork_logoNine.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenTen: file(relativePath: { eq: "case-studies/Flipwork_logoTen.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screenEleven: file(relativePath: { eq: "case-studies/Flipwork_logoEleven.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default Flipwork
